<template>
	<div class="berthplanner-timeline">
		<v-timeline dense>
			<v-timeline-item v-for="(event, index) in events" :key="index" fill-dot right
				:small="isSmallEvent(event)" :color="getEventColor(event)">
				<template v-slot:icon>
					<span class="white--text">{{ index + 1 }}</span>
				</template>
				<div slot="opposite" class="opposite-width-force">
					<span>{{ getEventValue(event) }}</span>
				</div>
				<div>
					<strong>{{ getEventLabel(event) }}</strong>
				</div>
			</v-timeline-item>
		</v-timeline>
	</div>
</template>

<script>
export default {
	props: {
		stop: {
			type: Object
		}
	},
	data() {
		return {
			eventsPro: ['pba','fla','afa','fcl','lcl','rts','pbd','lla'],
			eventsLite: ['fla','afa','fcl','lcl','lla']
		};
	},
	computed: {
		events() {
			return this.$store.getters.isBerthPlannerPro ? this.eventsPro: this.eventsLite;
		},
		dateTimeFormat() {
			return `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
		}
	},
	methods: {
		isSmallEvent(event) {
			return (this.getEventValue(event) === 'Not available');
		},
		getEventColor(event) {
			return this.isSmallEvent(event) ? 'error' : 'success';
		},
		getEventValue(event) {
			if (this.stop[event]) {
				return this.$dateTimeUtils.formatDate(this.stop[event], this.dateTimeFormat);
			}
			return 'Not available';
		},
		getEventLabel(event) {
			const eventName = event.toUpperCase();
			const eventDesc = this.$t(`events.${event}.description`);
			return `${eventName} (${eventDesc})`;
		}
	}
}
</script>
