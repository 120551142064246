<template>
	<v-card class="pt-0 px-2 pb-4">
		<v-card-title>
			<span class="operations-map__title">{{ vesselimonameportcallnumber }} - {{ getStopStatus(vessel) }} {{ getStopPhase(vessel) }}</span>
			<v-spacer />
			<BerthPlannerVesselDialogNotifications
				v-if="getStopStatus(vessel) !== 'Finalizado' && userFunctionalities.has_CREATE_SUBSCRIPTION"
				:stop="vessel"
			></BerthPlannerVesselDialogNotifications>
			<v-btn
				outlined
				small
				class="mx-0 ml-1"
				@click="goTo(vessel)"
				v-if="userFunctionalities.has_DETAIL_STOP_DATA_IN_BP || userFunctionalities.has_DETAIL_SHIPPOSITIONING_DATA_IN_BP"
			>
				{{ $t('operations.stops.moreData') }}
			</v-btn>
			<v-btn outlined small @click="closeDialog()" class="ml-2 mr-n2"><v-icon>fas fa-times</v-icon></v-btn>
		</v-card-title>

		<v-divider></v-divider>

		<v-container>
			<v-form v-model="formValidation" :disabled="vesselModel.statusid === 'AC'">
				<v-row v-if="userFunctionalities.has_DETAIL_ALERTS_BERTHING_PLAN">
					<v-col cols="12" v-if="vessel.delayeta">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="delayEta">{{
							$t('stop.status.delayed.eta')
						}}</v-alert>
					</v-col>
					<v-col cols="12" v-if="vessel.delayetc">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="delayEtc">{{
							$t('stop.status.delayed.etc')
						}}</v-alert>
					</v-col>
					<v-col cols="12" v-if="vessel.lessthantwentyfourtoeta">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="lessThan24ToEta">{{
							$t('berthplanner.vessel.lessthantwentyfourtoeta')
						}}</v-alert>
					</v-col>
					<v-col cols="12" v-if="vessel.thirtyminstoetc">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="30ToEtc">{{
							$t('berthplanner.vessel.thirtyminstoetc')
						}}</v-alert>
					</v-col>
					<v-col cols="12" v-else-if="vessel.sixtyminstoetc">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="60ToEtc">{{
							$t('berthplanner.vessel.sixtyminstoetc')
						}}</v-alert>
					</v-col>
					<v-col cols="12" v-else-if="vessel.ninetyminstoetc">
						<v-alert dense text color="red" icon="fas fa-exclamation-circle" class="mb-0" data-test="90ToEtc">{{
							$t('berthplanner.vessel.ninetyminstoetc')
						}}</v-alert>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="6" data-test="vesseltype">
						<b> {{ $t('berthplanner.vessel.type') }}: &nbsp;</b>{{ vessel.vesseltype }}
					</v-col>
					<v-col cols="3" data-test="vessellength">
						<b>{{ $t('berthplanner.vessel.length') }}: &nbsp;</b>{{ vessel.vessellength }}
					</v-col>
					<v-col cols="3" data-test="vesselbeam">
						<b>{{ $t('berthplanner.vessel.beam') }}: &nbsp;</b>{{ vessel.vesselbeam }}
					</v-col>

					<v-col cols="8" data-test="companyname" class="py-0">
						<b>{{ $t('berthplanner.vessel.terminal') }}: &nbsp;</b>{{ vessel.companyname }}
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="py-4">
						<h3>{{ $t('berthplanner.portstayinformation') }}</h3>
					</v-col>
				</v-row>

				<v-row class="indented" v-if="userFunctionalities.has_DETAIL_BOLLARD_IN_BP || userFunctionalities.has_DETAIL_BERTH_TYPE_IN_BP">
					<v-col cols="6" data-test="bollardini" class="pa-0" v-if="userFunctionalities.has_DETAIL_BOLLARD_IN_BP">
						<div v-if="!editMode">
							<b>{{ $t('berthplanner.vessel.bollards') }}: &nbsp;</b>
							{{
								(vesselModel.bollardinicode ? vesselModel.bollardinicode : '') +
								' - ' +
								(vesselModel.bollardendcode ? vesselModel.bollardendcode : '')
							}}
						</div>
						<div v-if="editMode" class="d-flex">
							<b>{{ $t('berthplanner.vessel.bollards') }}: &nbsp;</b>
							<pui-select
								v-model="vesselModel"
								modelName="bollard"
								:id="`bollardini-vesseldialog`"
								:attach="`bollardini-vesseldialog`"
								:label="$t('stopblock.bollardini')"
								:modelFormMapping="{ id: 'bollardiniid', bolcode: 'bollardinicode', orderby: 'bollardiniorderby' }"
								:itemsToSelect="itemsToSelectBollardIni"
								:itemValue="['id', 'bolcode', 'orderby']"
								itemText="bolcode"
								:fixedFilter="berthFilter"
								:order="{ orderby: 'asc' }"
								:rules="bollardRules"
								class="pl-4 pr-2"
								toplabel
								clearable
								reactive
							></pui-select>
							<pui-select
								v-model="vesselModel"
								modelName="bollard"
								:id="`bollardend-vesseldialog`"
								:attach="`bollardend-vesseldialog`"
								:label="$t('stopblock.bollardend')"
								:modelFormMapping="{ id: 'bollardendid', bolcode: 'bollardendcode', orderby: 'bollardendorderby' }"
								:itemsToSelect="itemsToSelectBollardEnd"
								:itemValue="['id', 'bolcode', 'orderby']"
								itemText="bolcode"
								:fixedFilter="berthFilter"
								:order="{ orderby: 'asc' }"
								:rules="bollardRules"
								class="pl-2 pr-4"
								toplabel
								clearable
								reactive
							></pui-select>
						</div>
					</v-col>
					<v-col cols="6" data-test="berthingposition" class="pa-0" v-if="userFunctionalities.has_DETAIL_BERTH_TYPE_IN_BP">
						<div v-if="!editMode">
							<b>{{ $t('berthplanner.vessel.berthingtype') }}: &nbsp;</b>{{ vesselModel.berthingtypename }}
						</div>
						<div v-if="editMode" class="d-flex">
							<b>{{ $t('berthplanner.vessel.berthingtype') }}: &nbsp;</b>
							<pui-select
								v-model="vesselModel"
								modelName="berthingtype"
								:id="`berthingtype-vesseldialog`"
								:ref="`berthingtype-vesseldialog`"
								:attach="`berthingtype-vesseldialog`"
								:labelBak="$t('stop.berthingtypesoli')"
								:modelFormMapping="{ berthingtypecode: 'berthingtypecode' }"
								:itemsToSelect="itemsToSelectBerthingType"
								:itemValue="['berthingtypecode']"
								itemText="berthingtypename"
								:order="{ berthingtypename: 'asc' }"
								class="px-4"
								toplabel
								clearable
								reactive
							></pui-select>
						</div>
					</v-col>
				</v-row>

				<v-row v-if="showEtaEtdAgent(vessel) && $store.getters.isBerthPlannerPro" class="indented">
					<v-col cols="6" class="pa-0 pt-2" v-if="vessel.eta && !editMode">
						<span>
							<b>{{ $t('berthplanner.vessel.eta.title') }}: &nbsp;</b>{{ $dateTimeUtils.formatDate(vessel.eta, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="6" v-if="vessel.eta && editMode" class="pl-0 d-flex">
						<b class="float-left">{{ $t('berthplanner.vessel.eta.title') }}: &nbsp;</b>
						<pui-date-field
							:id="`dateini-vesseldialog`"
							:label="$t('stopblock.dateini')"
							v-model="vesselModel.eta"
							:max="vesselModel.etd"
							class="pl-4"
							toplabel
							time
						></pui-date-field>
					</v-col>

					<v-col cols="6" class="pa-0 pt-2" v-if="vessel.eta && !editMode">
						<span>
							<b>{{ $t('berthplanner.vessel.etd.title') }}: &nbsp;</b>{{ $dateTimeUtils.formatDate(vessel.etd, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="6" v-if="vessel.etd && editMode" class="pl-0 d-flex">
						<b>{{ $t('berthplanner.vessel.etd.title') }}: &nbsp;</b>
						<pui-date-field
							:id="`dateend-vesseldialog`"
							:label="$t('stopblock.dateend')"
							v-model="vesselModel.etd"
							:min="vesselModel.eta"
							class="pl-4"
							toplabel
							time
						></pui-date-field>
					</v-col>
				</v-row>

				<v-row v-if="showEtaEtdTerminal(vessel) && 1 == 2" class="indented">
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.etaterminal">
							<b>{{ $t('berthplanner.vessel.etaterminal') }}: </b
							>{{ $dateTimeUtils.formatDate(vessel.etaterminal, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.etc">
							<b>{{ $t('berthplanner.vessel.etc.title') }}: </b>{{ $dateTimeUtils.formatDate(vessel.etc, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.etdterminal">
							<b>{{ $t('berthplanner.vessel.etdterminal') }}: </b
							>{{ $dateTimeUtils.formatDate(vessel.etdterminal, this.dateTimeFormat) }}
						</span>
					</v-col>
				</v-row>

				<v-row v-if="showAtaAtd(vessel)" class="indented">
					<v-col cols="6" class="pl-0">
						<span v-if="vessel.ata">
							<b>{{ $t('berthplanner.vessel.ata.title') }}: </b>{{ $dateTimeUtils.formatDate(vessel.ata, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="6" class="pl-0">
						<span v-if="vessel.atd">
							<b>{{ $t('berthplanner.vessel.atd.title') }}: </b>{{ $dateTimeUtils.formatDate(vessel.atd, this.dateTimeFormat) }}
						</span>
					</v-col>
				</v-row>

				<v-row v-if="showAtaAtdTerminal(vessel)" class="indented">
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.ataterminal">
							<b>{{ $t('berthplanner.vessel.ataterminal') }}: </b
							>{{ $dateTimeUtils.formatDate(vessel.ataterminal, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.atc">
							<b>{{ $t('berthplanner.vessel.atc.title') }}: </b>{{ $dateTimeUtils.formatDate(vessel.atc, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="4" class="pl-0">
						<span v-if="vessel.atdterminal">
							<b>{{ $t('berthplanner.vessel.atdterminal') }}: </b
							>{{ $dateTimeUtils.formatDate(vessel.atdterminal, this.dateTimeFormat) }}
						</span>
					</v-col>
				</v-row>

				<v-row v-if="showRtsRtsTerminal(vessel)" class="indented">
					<v-col cols="6" class="pl-0">
						<span v-if="vessel.rts">
							<b>{{ $t('berthplanner.vessel.rts') }}: </b>{{ $dateTimeUtils.formatDate(vessel.rts, this.dateTimeFormat) }}
						</span>
					</v-col>
					<v-col cols="6" class="pl-0">
						<span v-if="vessel.rtsterminal">
							<b>{{ $t('berthplanner.vessel.rtsterminal') }}: </b
							>{{ $dateTimeUtils.formatDate(vessel.rtsterminal, this.dateTimeFormat) }}
						</span>
					</v-col>
				</v-row>

				<v-row v-if="userFunctionalities.has_DETAIL_KEEP_CHANGES_IN_BP && editMode && vesselModel.statusid !== 'AC'">
					<v-col class="pt-0">
						<v-btn class="float-left" outlined @click.native="closeDialog()">{{ $t('berthplanner.discardchanges') }}</v-btn>
						<v-btn class="float-right" color="primary" @click.native="keep()">{{ $t('berthplanner.keepchanges') }}</v-btn>
					</v-col>
				</v-row>

				<v-row v-if="showMap">
					<v-col cols="12">
						<h3>{{ $t('berthplanner.realtimelocation') }}</h3>
					</v-col>
					<v-col cols="12">
						<operations-visit-map-content :stopPosition="stopPosition" />
					</v-col>
				</v-row>

				<v-row v-if="showTimeline(vessel)">
					<v-col cols="12">
						<berth-planner-vessel-dialog-timeline :stop="vessel" />
					</v-col>
				</v-row>
				<div class="pui-form">
					<v-form class="mb-4 pb-4" ref="form" v-model="stopFormValidation" lazy-validation @submit.prevent>
						<custom-fields-generate-form
							:formDisabled="true"
							:isCreatingElement="false"
							:parentObject="vesselModel"
							parentModelName="shippositioning"
							:parentPk="getParentPk()"
						></custom-fields-generate-form>
					</v-form>
				</div>
			</v-form>
		</v-container>
	</v-card>
</template>

<script>
import OperationsVisitMapContent from '@/components/operations/gantt/OperationsVisitMapContent.vue';
import BerthPlannerVesselDialogTimeline from './BerthPlannerVesselDialogTimeline.vue';
import BerthPlannerVesselDialogNotifications from './BerthPlannerVesselDialogNotifications.vue';

/** Mixins */
import AISServiceMixin from '@/mixins/AISServiceMixin';

export default {
	name: 'BerthPlannerVesselDialog',
	mixins: [AISServiceMixin],

	components: { BerthPlannerVesselDialogTimeline, BerthPlannerVesselDialogNotifications, OperationsVisitMapContent },

	props: {
		vessel: {
			type: Object
		},
		readOnly: {
			type: Boolean,
			default: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			stopPosition: null,
			vesselModel: this.vessel,
			formValidation: false,
			stopFormValidation: false
		};
	},

	computed: {
		vesselimonameportcallnumber() {
			if (!this.vessel) {
				return '';
			}
			return this.vessel.portcallnumber
				? `${this.vessel.vesselimo} - ${this.vessel.vesselname} (${this.vessel.portcallnumber})`
				: `${this.vessel.vesselimo} - ${this.vessel.vesselname}`;
		},
		berthFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berth_id', op: 'eq', data: this.vessel.berthid }]
			};
		},
		itemsToSelectBollardIni: {
			get: function () {
				return [{ id: this.vesselModel.bollardiniid, bolcode: this.vesselModel.bollardinicode, orderby: this.vesselModel.bollardiniorderby }];
			},
			set: function (newValue) {
				this.vesselModel.bollardiniid = newValue.id;
				this.vesselModel.bollardinicode = newValue.bolcode;
				this.vesselModel.bollardiniorderby = newValue.orderby;
			}
		},
		itemsToSelectBollardEnd: {
			get: function () {
				return [{ id: this.vesselModel.bollardendid, bolcode: this.vesselModel.bollardendcode, orderby: this.vesselModel.bollardendorderby }];
			},
			set: function (newValue) {
				this.vesselModel.bollardendid = newValue.id;
				this.vesselModel.bollardendcode = newValue.bolcode;
				this.vesselModel.bollardendorderby = newValue.orderby;
			}
		},
		bollardRules() {
			return [
				this.vesselModel.bollardiniorderby < this.vesselModel.bollardendorderby ||
					(!this.vesselModel.bollardiniorderby && !this.vesselModel.bollardendorderby) ||
					this.$t('stop.bollardsException')
			];
		},
		itemsToSelectBerthingType: {
			get: function () {
				return [{ berthingtypecode: this.vesselModel.berthingtypecode }];
			},
			set: function (newValue) {
				this.vesselModel.berthingtypecode = newValue.berthingtypecode;
			}
		},
		showMap() {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'NR'];
			return this.stopPosition && this.vessel && this.vessel.vesselimo && statusValues.includes(this.vesselModel.statusid);
		},
		dateTimeFormat() {
			return `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
		},

		statusItems() {
			return this.$store.getters.stopsStatusValues && this.$store.getters.stopsStatusValues.filter((status) => status.visibleberthingplan);
		},

		initiatedStatusOrder() {
			return (initiatedOrder = this.statusItems.find((status) => status.stopstatuscode === 'IN'));
		},

		currentStatusOrder() {
			return (initiatedOrder = this.statusItems.find((status) => status.stopstatuscode === 'IN'));
		},

		isInitiated() {
			let initiatedOrder = this.statusItems.find((status) => status.stopstatuscode === 'IN');
			let currentStatusOrder = this.statusItems.find((status) => status.stopstatuscode === this.vesselModel.statusid);
			return currentStatusOrder >= initiatedOrder;
		},

		//
		// PERMISOS
		//

		editMode() {
			return !this.readOnly && this.userFunctionalities.has_EDIT_STOP && this.vesselModel.statusid !== 'FI';
		}
	},

	watch: {
		'vesselModel.berthingtypecode'(v) {
			if (this.$refs['berthingtype-vesseldialog'] && this.$refs['berthingtype-vesseldialog'].selectedItems) {
				this.vesselModel.berthingportstarboard = this.$refs['berthingtype-vesseldialog'].selectedItems.portstarboard;
			}
		}
	},

	created() {
		this.getAisPosition(this.vessel);
		if (!this.editMode) {
			this.getBerthingTypeByCode(this.vesselModel.berthingtypecode);
		}
	},

	methods: {
		async getAisPosition(stop) {
			this.stopPosition = await this.getAISVesselsPosition(stop);
		},
		getParentPk() {
			return btoa(`{ "id": ${this.vessel.id} }`);
		},
		getBerthingTypeByCode(code) {
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'berthingtype',
					filter: { groupOp: 'and', groups: [], rules: [{ data: code, field: 'berthingtypecode', op: 'eq' }] }
				},
				(response) => {
					if (response && response.data && response.data.data[0] && response.data.data[0].berthingtypename) {
						this.$set(this.vesselModel, 'berthingtypename', response.data.data[0].berthingtypename);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getStopStatus(stop) {
			return stop.status ? this.$t(`stop.status.${stop.statusid.toLowerCase()}.title`) : stop.status;
		},
		getStopPhase(stop) {
			const statusValues = ['IN', 'SO', 'EO'];
			return stop.phase && statusValues.includes(stop.statusid) ? `(${stop.phase})` : '';
		},
		showEtaEtdAgent(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'IN'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showEtaEtdTerminal(stop) {
			const statusValues = ['RQ', 'AC', 'AU', 'CO', 'WA', 'IN', 'NR'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showAtaAtd(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showAtaAtdTerminal(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		showRtsRtsTerminal(stop) {
			return stop.vesselimo && stop.statusid == 'FI';
		},
		showTimeline(stop) {
			const statusValues = ['IN', 'SO', 'EO', 'FI'];
			console.log('showTimeline', stop.vesselimo && statusValues.includes(stop.statusid));
			return stop.vesselimo && statusValues.includes(stop.statusid);
		},
		closeDialog() {
			this.$emit('close-dialog');
		},
		keep() {
			if (this.formValidation) {
				//this.$puiEvents.$emit(`berthplanner-vesselDialog_update`, this.vesselModel);
				this.updateStop();
				this.closeDialog();
			}
		},
		goTo(stop) {
			let visit = btoa(`{ "id": ${stop.visitid} }`);
			stop = stop && stop.id ? btoa(`{ "id": ${stop.id} }`) : btoa(`{ "id": ${stop.stopid} }`);

			if (this.userFunctionalities.has_DETAIL_STOP_DATA_IN_BP) {
				this.$router.push('/stop/update/' + stop);
			}

			if (this.userFunctionalities.has_DETAIL_SHIPPOSITIONING_DATA_IN_BP) {
				this.$router.push('/shippositioning/update/' + stop);
			}
		},
		updateStop() {
			new Promise(async (resolve) => {
				await this.$puiRequests.patchRequest(
					'/stop/patch?id=' + this.vesselModel.id,
					{
						bollardiniid: this.vesselModel.bollardiniid,
						bollardendid: this.vesselModel.bollardendid,

						berthingtype: this.vesselModel.berthingtypecode,

						startdate: this.vesselModel.eta,
						enddate: this.vesselModel.etd
					},
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('berthplanner-toolbar_auth');
						resolve(true);
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						resolve(false);
					}
				);
			});
		}
	}
};
</script>

<style lang="postcss">
.indented {
	margin-left: 10px;
}
</style>
