<template>
	<v-dialog v-model="visible" width="60%" scrollable>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.weatherInfo') }}</span>
				<v-spacer />
				<span class="pr-8"
					><strong>{{ currentPort.portname }}</strong></span
				>
				<span class="pr-8">{{ activeDate.toLocaleDateString() }}</span>
				<v-btn outlined small @click="visible = false"><v-icon>fas fa-times</v-icon></v-btn>
			</v-card-title>
			<v-divider />
			<v-card-text class="pa-0">
				<v-data-table :headers="headers" :items="items" hide-default-footer disable-sort class="weatherInfo elevation-0">
					<template v-slot:header.name="{ header }">
						<span class="font-weight-bold"> {{ header.text }}</span>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import WeatherService from '@/services/weather.service.js';

export default {
	props: {
		currentPort: {
			required: true
		}
	},
	data() {
		return {
			visible: false,
			hourlyForecast: {},
			activeDate: new Date(),
			headers: [],
			items: []
		};
	},
	computed: {},
	watch: {
		currentPort() {
			this.getHourlyForecastFor5Days();
		}
	},
	mounted() {
		this.mountHeaders();
		this.getHourlyForecastFor5Days();
		this.$puiEvents.$on('berthplanner-toolbar_dateSelected', (dateSelected) => {
			this.activeDate = dateSelected.startDate;
		});
		//this.pruebaMapa();
	},
	methods: {
		mountHeaders() {
			this.headers.push({
				text: this.$t('berthplanner.hours'),
				value: 'name'
			});

			let i = 1;
			i += this.getHourTimezoneOffset();

			for (i; i <= 24; i = i + 3) {
				let newHeader = {
					text: i < 10 ? '0' + i + ':00' : i + ':00',
					value: 'hour' + i
				};

				if (newHeader.text == '24:00') {
					newHeader = {
						text: '00:00',
						value: 'hour0'
					};
				}

				this.headers.push(newHeader);
			}
		},
		async getHourlyForecastFor5Days() {
			if (this.currentPort && this.currentPort.latitude != null && this.currentPort.longitude != null) {
				this.hourlyForecast = await WeatherService.getHourlyForecastFor5Days(this.currentPort.latitude, this.currentPort.longitude);

				if (this.hourlyForecast) {
					this.mountItems();
				}
			}
		},
		mountItems() {
			this.items = [];
			let item_velocidad = {};
			let item_direccion = {};

			this.hourlyForecast.forEach((element) => {
				var forecastDate = new Date(element.dt * 1000);
				if (
					forecastDate.getDay() == this.activeDate.getDay() ||
					(forecastDate.getDay() == this.activeDate.getDay() + 1 && forecastDate.getHours() == this.getHourTimezoneOffset())
				) {
					var value = 'hour' + (forecastDate.getHours() - this.getHourTimezoneOffset());
					item_velocidad[value] = Math.round(element.wind.speed * 10) / 10 + ' m/s';
					item_velocidad.name = 'Velocidad';
					item_direccion[value] = element.wind.deg + 'º (' + WeatherService.getWindDirectionFromWindDegrees(element.wind.deg) + ')';
					item_direccion.name = 'Dirección';
				}
			});
			this.items.push(item_velocidad);
			this.items.push(item_direccion);
		},

		getHourTimezoneOffset() {
			return -(new Date().getTimezoneOffset() / 60);

			if (new Date().getTimezoneOffset() == -120) {
				// Summer time España 2
				return 2;
			}

			if (new Date().getTimezoneOffset() == -60) {
				// Winter time España 1
				return 1;
			}
		},

		pruebaMapa() {
			const portId = window.localStorage.workingPortsId;
			if (portId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: portId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, async (response) => {
					if (response.data.data[0].latitude && response.data.data[0].longitude) {
						this.portLatitude = response.data.data[0].latitude;
						this.portLongitude = response.data.data[0].longitude;
						var data = await WeatherService.getWindMap(this.portLatitude, this.portLongitude);
					}
				});
			}
		}
	}
};
</script>
<style lang="postcss">
.weatherInfo th:not(:first-child) {
	color: #2d78ef !important;
}
.weatherInfo td:first-child {
	font-weight: bold;
}
</style>
