import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointItemTypes } from '../../items/JointTypes.js';

class JointBollardsAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, bollards, bollardNodes, berths) {
		this.jointMain = jointMain;

		this.bollards = bollards;
		this.bollardNodes = bollardNodes;
		this.berths = berths;

		this.berthsEnabled = berths && berths.length > 0;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		this.availableSpaceForBollards = this.jointMain.paperWidth - this.leftAxisWidth - JointSettings.axis.bollardSeparator;

		// test / no se usa - berthItemWidth es proporcional al número de bolardos del berth, no equidistante
		if (this.berths && this.berths.length > 0) {
			this.berthItemWidth = this.availableSpaceForBollards / this.berths.length;
		} else {
			this.berthItemWidth = this.availableSpaceForBollards;
		}

		if (this.bollardNodes.length == 0) {
			this.bollardItemWidth = this.availableSpaceForBollards;
		} else {
			this.bollardItemWidth = this.availableSpaceForBollards / (this.bollardNodes.length - 1);
		}
	}

	render() {
		let rowHeight = this.berthsEnabled ? this.topAxisHeight / 2 : this.topAxisHeight;
		this.berthsEnabled && this.renderBerthsRow(0, rowHeight);
		this.renderBollardsRow(this.berthsEnabled ? rowHeight : 0, rowHeight);
		this.renderDelimiters();
	}

	renderBerthsRow(y, height) {
		this.renderInitialSeparator(y, height);
		this.renderFinalSeparator(y, height);
		this.renderBerths(y, height);
	}

	renderBollardsRow(y, height) {
		this.renderInitialSeparator(y, height);
		this.renderFinalSeparator(y, this.jointMain.paperHeight);
		this.renderbollardNodes(y, height);
		//this.renderBollards(y, height);
	}

	renderDelimiters() {
		//this.renderBollardDelimiters();
		this.renderBollardNodeDelimiters();
		this.renderBerthLimits();
	}

	renderInitialSeparator(y, height) {
		let jsItemConf = new JointItemConf(JointItemTypes.Bollard, '', 0, y, this.leftAxisWidth, height);

		let initialSeparator = new JointItem(jsItemConf, this.jointMain);
		initialSeparator.render();
	}

	renderBerths(y, height) {
		let berthItemWidth = this.availableSpaceForBollards;

		// white blocks between berths
		let jsItemConf2 = new JointItemConf(JointItemTypes.Bollard, '', this.leftAxisWidth, y, this.availableSpaceForBollards, height);
		let whiteBackgroundRow = new JointItem(jsItemConf2, this.jointMain, null);
		whiteBackgroundRow.render();
		this.jointMain.jointZManager.setItem(whiteBackgroundRow);

		let berthItemWidthAcumulator = 0;
		for (let n = 0; n < this.berths.length; n++) {
			let correlativeBerth = this.berths[n].correlativeberth == true;

			if (correlativeBerth) {
				berthItemWidth = this.berthItemWidth;
			} else {
				berthItemWidth = (this.availableSpaceForBollards * (this.berths[n].bollardsLength - 1)) / (this.bollardNodes.length - 1);
			}

			let jsItemConf = new JointItemConf(
				JointItemTypes.Berth,
				this.berths[n].name,
				this.leftAxisWidth + berthItemWidthAcumulator,
				y,
				berthItemWidth,
				height
			);

			if (!correlativeBerth) {
				berthItemWidthAcumulator += this.bollardItemWidth;
			}

			berthItemWidthAcumulator += berthItemWidth;

			let berth = new JointItem(jsItemConf, this.jointMain, this.berths[n]);
			berth.render();

			this.jointMain.jointZManager.setItem(berth);
		}
	}

	renderBollards(y, height) {
		for (let n = 0; n < this.bollards.length; n++) {
			let bolcode = this.bollards[n].bolcode != 'start' && this.bollards[n].bolcode != 'end' ? this.bollards[n].bolcode : '';

			let jsItemConf = new JointItemConf(
				JointItemTypes.Bollard,
				bolcode,
				this.leftAxisWidth + this.bollardItemWidth * n,
				y,
				this.bollardItemWidth,
				height
			);

			let bollard = new JointItem(jsItemConf, this.jointMain, this.bollards[n]);
			bollard.render();

			this.jointMain.jointZManager.setItem(bollard);
		}
	}

	renderbollardNodes(y, height) {
		for (let n = 0; n < this.bollardNodes.length; n++) {
			let bollard = this.bollardNodes[n][0];

			let jsItemConf = new JointItemConf(
				JointItemTypes.Bollard,
				this.getBollardLabelFromCode(bollard),
				this.leftAxisWidth + this.bollardItemWidth * n,
				y,
				this.bollardItemWidth,
				height
			);

			let bollardNode = new JointItem(jsItemConf, this.jointMain, this.bollardNodes[n]);
			bollardNode.render();

			this.jointMain.jointZManager.setItem(bollardNode);
		}
	}

	getBollardLabelFromCode(bollard) {
		if (bollard && (bollard.bolcode.startsWith('start_') || bollard.bolcode.startsWith('end_'))) {
			return '';
		} else {
			return bollard.bolcode;
		}
	}

	renderFinalSeparator(y, height) {
		let width = this.bollards.length == 0 ? this.jointMain.paperWidth - this.leftAxisWidth : JointSettings.axis.bollardSeparator;

		let jsItemConf = new JointItemConf(JointItemTypes.Bollard, '', this.leftAxisWidth + this.availableSpaceForBollards, y, width, height);

		let finalSeparator = new JointItem(jsItemConf, this.jointMain);
		this.bollardNodes && this.bollardNodes.length > 0 && finalSeparator.render();

		this.jointMain.jointZManager.setItem(finalSeparator);
	}

	// DELIMITADORES DE BOLARDOS
	renderBollardDelimiters() {
		for (let n = 0; n < this.bollards.length; n++) {
			let line = V('line', {
				x1: this.leftAxisWidth + n * this.bollardItemWidth,
				y1: this.topAxisHeight,
				x2: this.leftAxisWidth + n * this.bollardItemWidth,
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	renderBollardNodeDelimiters() {
		for (let n = 0; n < this.bollardNodes.length; n++) {
			let line = V('line', {
				x1: this.leftAxisWidth + n * this.bollardItemWidth,
				y1: this.topAxisHeight,
				x2: this.leftAxisWidth + n * this.bollardItemWidth,
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	renderBerthLimits() {
		for (let n = 0; n < this.bollardNodes.length; n++) {
			let bollard = this.bollardNodes[n];
			let berthlimit = bollard[0] && bollard[0].berthlimit;
			let bolcode = bollard[0] && bollard[0].bolcode;

			if (berthlimit == true) {
				let jsItemConf = new JointItemConf(
					JointItemTypes.BerthLimit,
					'',
					this.leftAxisWidth + this.bollardItemWidth * n,
					bolcode.startsWith('start_') || bolcode.startsWith('end_') ? 20 : this.topAxisHeight,
					1,
					this.jointMain.paperHeight
				);

				let berthLimit = new JointItem(jsItemConf, this.jointMain, this.bollardNodes[n]);
				berthLimit.render();

				this.jointMain.jointZManager.setItem(berthLimit);
			}
		}
	}

	getStartBerthLimitBolcodeFromBerthId(berthid) {
		if (this.berths && this.berths.length == 0) {
			return this.getXpositionFromTopItemCode(this.bollardNodes[0][0].bolcode);
		} else {
			let bolcode = 'start_' + berthid;
			return this.getXpositionFromTopItemCode(bolcode);
		}
	}

	//	Si no hay berths, no es un dock, es un berth único y devolvemos como límites los bolardos extremos
	//	Si son terminales como las de perú (sin bolardos), busca los bolcodes genéricos start y end para los límites
	//  Si es un muelle con varios berths y tienen bolardos, hay que recorrer los bolardos y ver cuales son los limites, no está hecho

	getEndBerthLimitBolcodeFromBerthId(berthid) {
		if (this.berths && this.berths.length == 0) {
			return this.getXpositionFromTopItemCode(this.bollardNodes[this.bollardNodes.length - 1][0].bolcode);
		} else {
			let bolcode = 'end_' + berthid;
			return this.getXpositionFromTopItemCode(bolcode);
		}
	}

	getXpositionFromTopItemCode(bolcode) {
		// bookings bolcodes come as number and we check === to prevent undefined / null values
		if (bolcode) {
			bolcode = bolcode.toString();
		}

		for (let n = 0; n < this.bollardNodes.length; n++) {
			let bollardNode = this.bollardNodes[n];

			let firstBollardCode = bollardNode[0] && bollardNode[0].bolcode;
			let secondBollardCode = bollardNode[1] && bollardNode[1].bolcode;

			if (bolcode === firstBollardCode || bolcode === secondBollardCode) {
				return this.leftAxisWidth + n * this.bollardItemWidth;
			}
		}
		return null;
	}

	getClosestBollardFromXposition(x) {
		let leftBollard;
		let rightBollard;

		let leftBollardX;
		let rightBollardX;

		if (this.bollards && this.bollardNodes.length > 2) {
			for (let n = 0; n < this.bollardNodes.length - 1; n++) {
				leftBollard = this.bollardNodes[n][0];
				rightBollard = this.bollardNodes[n + 1][0];

				if (x < this.getXpositionFromTopItemCode(rightBollard.bolcode)) {
					break;
				}
			}

			leftBollardX = this.getXpositionFromTopItemCode(leftBollard.bolcode);
			rightBollardX = this.getXpositionFromTopItemCode(rightBollard.bolcode);
		}

		return x - leftBollardX < rightBollardX - x ? leftBollard : rightBollard;
	}
}

export default JointBollardsAxis;
