<template>
	<v-dialog v-model="visibleDialog" scrollable>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.optimize.title') }}</span>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3">
				<v-container class="py-0" v-if="!loading">
					<table class="berthplannerTable mb-4">
						<tr class="headers">
							<th v-for="(header, i) in headers" :key="i" class="font-weight-bold berthplannerTh">{{ header.name }}</th>
						</tr>
						<tr v-for="stop in data.stops" :key="stop.id" class="berthplannerTr">
							<td v-for="(header, o) in headers" :key="o" class="berthplannerTd">
								{{ stop[header.id] != null ? formatValue(stop[header.id]) : '' }}
								<v-tooltip top v-if="(stop[header.id] == null || stop[header.id] < 1) && header.required">
									<template v-slot:activator="{ on }">
										<v-icon x-small v-on="on" color="red">fas fa-exclamation-circle</v-icon>
									</template>
									<span>{{ getError(header.id, stop) }}</span>
								</v-tooltip>
							</td>
						</tr>
					</table>
					<span class="mt-10">
						<v-icon x-small color="red">fas fa-exclamation-circle</v-icon> {{ $t('berthplanner.optimize.advise') }}.</span
					>
				</v-container>
				<v-container class="px-6 py-0" v-else> <v-progress-linear indeterminate rounded height="6"></v-progress-linear></v-container>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions v-if="!loading">
				<v-spacer></v-spacer>
				<v-btn outlined @click="back">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" @click="conntinue">{{ $t('pui9.accept') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			visibleDialog: false,
			data: {},
			loading: false,
			headers: [
				{ id: 'portcallnumber', name: this.$t('berthplanner.optimize.portcallnumber'), required: true },
				{ id: 'vesselname', name: this.$t('berthplanner.optimize.vesselname'), required: true },
				{ id: 'caladoPopa', name: this.$t('berthplanner.optimize.caladoPopa'), required: true },
				{ id: 'vesselSdw', name: this.$t('berthplanner.optimize.vesselSdw'), required: true },
				{ id: 'vessellength', name: this.$t('berthplanner.optimize.vessellength'), required: false },
				{ id: 'operation', name: this.$t('berthplanner.optimize.operation'), required: true },
				{ id: 'product', name: this.$t('berthplanner.optimize.product'), required: true },
				{ id: 'diaryCost', name: this.$t('berthplanner.optimize.diaryCost'), required: true },
				{ id: 'cuantityCO', name: this.$t('berthplanner.optimize.cuantityCO'), required: true },
				{ id: 'laycanIni', name: this.$t('berthplanner.optimize.laycanIni'), required: false },
				{ id: 'laycanFin', name: this.$t('berthplanner.optimize.laycanFin'), required: false }
			]
		};
	},
	watch: {
		data: {
			handler() {
				console.log('Data changed:', this.data);
			},
			deep: true
		}
	},
	mounted() {},
	methods: {
		formatValue(value) {
			if (this.isDate(value)) {
				return this.formatDate(value);
			}
			return value;
		},
		isDate(value) {
			if (typeof value === 'string' && !isNaN(Date.parse(value))) {
				const date = new Date(value);
				return date instanceof Date && !isNaN(date);
			}
			return false;
		},
		formatDate(value) {
			const date = new Date(value);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${day}-${month}-${year}`;
		},
		getError(header, stop) {
			stop.error = true;

			switch (header) {
				case 'operation':
				case 'product':
					return this.$t('berthplanner.optimize.errorNull');
				case 'cuantityCO':
				case 'caladoPopa':
				case 'vesselSdw':
				case 'diaryCost':
					return this.$t('berthplanner.optimize.errorMinor');
				default:
					break;
			}
		},
		back() {
			this.$emit('response', false);
			this.visibleDialog = false;
		},
		conntinue() {
			let dataToOptimize = structuredClone(this.data);
			dataToOptimize.stops = dataToOptimize.stops.filter((stop) => {
				return stop.error == null;
			});
			if (dataToOptimize.stops.length == 0) {
				this.$puiNotify.error(this.$t('berthplanner.optimize.errorNoData'));
				return;
			}
			this.loading = true;
			this.$puiRequests.postRequest(
				'/vstopberthplanner/optimize',
				dataToOptimize,
				(response) => {
					try {
						if (response.status === 200) {
							this.obtainStops(response.data);
						} else {
							this.loading = false;
							this.$store.dispatch('puiRequestShowServerError', { error: response, vue: this });
						}
					} catch (error) {
						this.loading = false;
						console.error(error);
					}
				},
				(error) => {
					this.loading = false;
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		obtainStops(id) {
			this.$puiRequests.postRequest(
				'/vstopberthplanner/obtainStops',
				{ dock: this.data.dock, berths: this.data.berths, startDate: this.data.startDate, endDate: this.data.endDate },
				(response) => {
					try {
						this.$puiEvents.$emit('berthplanner-toolbar_opt', response.data.data);
						this.loading = false;
						this.visibleDialog = false;
					} catch (error) {
						this.loading = false;
						console.error(error);
					}
				},
				(error) => {
					this.loading = false;
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				null,
				{ id: id }
			);
		}
	}
};
</script>
