<template>
	<v-dialog v-model="visibleDialog" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.changeslost_title') }}</span>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3 d-flex">
				<v-icon color="red accent-4">fas fa-exclamation-triangle</v-icon>
				<v-container class="px-6 py-0">{{ $t('berthplanner.changeslost_description') }}</v-container>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn outlined @click="back">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" @click="conntinue">{{ $t('pui9.accept') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'BerthPlannerSaveChangesDialog',
	data() {
		return {
			visibleDialog: false
		};
	},
	watch: {},
	mounted() {},
	methods: {
		back() {
			this.visibleDialog = false;
		},
		conntinue() {
			this.$emit('response');
			this.visibleDialog = false;
		}
	}
};
</script>
