import axios from 'axios';

const apiKey = window.localStorage.getItem('weatherKey');

const externalService = {
	/**
	 * Get daily forecast for 7 days using external service (http://api.weatherapi.com/v1/forecast.json)
	 *
	 * @returns weather (Object)
	 */
	getDailyForecast: async function (lat, lon, type) {
		try {
			// http://api.weatherapi.com/v1/forecast.json?key=XXXXXXXXXXXXXXXXXXXXXXX&q=-11.92056,-77.13333&days=7&aqi=no&alerts=no
			// https://api.weatherapi.com/v1/marine.json?key=XXXXXXXXXXXXXXXXXXXXXXX&q=-11.92056,-77.13333&days=5
			const coordinates = lat + ',' + lon;
			let days = type === 'marine' ? 5 : 7;
			let url = `https://api.weatherapi.com/v1/${type}.json?key=${apiKey}&q=${coordinates}&days=${days}`;

			if (type === 'forecast') {
				url += '&aqi=no&alerts=no';
			}

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.log('Bad request', response);
				return;
			} else if (!response.data || !response.data.forecast.forecastday || !Array.isArray(response.data.forecast.forecastday)) {
				console.log('No response.data.forecast.forecastday or empty array', response);
				return;
			}

			return response.data.forecast.forecastday;
		} catch (err) {
			console.error(err);
		}
	},
	getWindDirectionFromWindDegrees: function (degrees) {
		// http://snowfence.umn.edu/Components/winddirectionanddegrees.htm
		if (degrees > 348.75 || degrees <= 11.25) {
			return 'N';
		} else if (degrees > 11.25 && degrees <= 33.75) {
			return 'NNE';
		} else if (degrees > 33.75 && degrees <= 56.25) {
			return 'NE';
		} else if (degrees > 56.25 && degrees <= 78.75) {
			return 'ENE';
		} else if (degrees > 78.75 && degrees <= 101.25) {
			return 'E';
		} else if (degrees > 101.25 && degrees <= 123.75) {
			return 'ESE';
		} else if (degrees > 123.75 && degrees <= 146.25) {
			return 'SE';
		} else if (degrees > 146.25 && degrees <= 168.75) {
			return 'SSE';
		} else if (degrees > 168.75 && degrees <= 191.25) {
			return 'S';
		} else if (degrees > 191.25 && degrees <= 213.75) {
			return 'SSW';
		} else if (degrees > 213.75 && degrees <= 236.25) {
			return 'SW';
		} else if (degrees > 236.25 && degrees <= 258.75) {
			return 'WSW';
		} else if (degrees > 258.75 && degrees <= 281.25) {
			return 'W';
		} else if (degrees > 281.25 && degrees <= 303.75) {
			return 'WNW';
		} else if (degrees > 303.75 && degrees <= 326.25) {
			return 'NW';
		} else if (degrees > 326.25 && degrees <= 348.75) {
			return 'NNW';
		}
	},
	getHourlyForecastFor5Days: async function (lat, lon) {
		try {
			// https://api.openweathermap.org/data/2.5/onecall?lat=36.13326&lon=-5.45051&exclude=minutely,hourly,alerts&appid=d886ed6111ee5e1ba54b0a310c67d537
			const coordinates = lat + ',' + lon;
			//const latitude = lat;
			//const longitude = lon;
			//const units = 'metric';
			const url = `/openweathermap/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${apiKey}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.log('Bad request', response);
				return;
			} else if (!response.data || !response.data.list || !Array.isArray(response.data.list)) {
				console.log('No response.data.daily or empty array', response);
				return;
			}
			return response.data.list;
		} catch (err) {
			console.error(err);
		}
	},
	getWindMap: async function (lat, lon) {
		try {
			//https://openweathermap.org/weathermap?basemap=map&cities=false&layer=windspeed&lat=36.1401&lon=-5.4465&zoom=10
			//https://tile.openweathermap.org/map/{layer}/{z}/{x}/{y}.png?appid={API key}
			// `/openweathermap/map?basemap=map&cities=false&layer=windspeed&lat=${latitude}&lon=${longitude}&appid=${apiKey}&zoom=10`
			const latitude = lat;
			const longitude = lon;
			const units = 'metric';

			const url = `/openweathermap/map/wind_new/13/36/5?appid=${apiKey}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.log('Bad request', response);
				return;
			} else if (!response.data) {
				console.log('No response.data.daily or empty array', response);
				return;
			}
			console.log(response.data);
			return response.data;
		} catch (err) {
			console.error(err);
		}
	}
};

export default externalService;
