<template>
	<v-container class="dateItem pa-0 ma-0">
		<v-col v-if="!dateSelectorVisible" class="pa-0 mr-2">
			<v-btn text @click="dateSelectorVisible = true">{{ days }} {{ $t('form.resourceblock.days') }}</v-btn>
		</v-col>
		<v-col v-else class="pa-0 d-flex">
			<v-btn x-small text @click.native="incrementOrDecrementDay(-1)" class="pa-0 py-4 mt-1" :disabled="disabled">
				<v-icon class="pa-0">far fa-chevron-left</v-icon>
			</v-btn>
			<div>
				<v-menu
					v-model="menuDate"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
					style="z-index: 101"
					:disabled="disabled"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-if="!daysCounterVisible"
							v-model="selectedStringFormattedDate"
							v-on="on"
							v-bind="attrs"
							prepend-inner-icon="far fa-calendar"
							readonly
							solo
							flat
							hide-details
							class="date-field-start"
						></v-text-field>
						<v-text-field
							v-else
							v-model="selectedStringFormattedDate"
							v-on="on"
							v-bind="attrs"
							readonly
							solo
							flat
							hide-details
							class="date-field-end"
							append-icon="far fa-times"
							@click:append="dateSelectorVisible = false"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="selectedStringDate"
						first-day-of-week="1"
						no-title
						:disabled="disabled"
						@change="onDatePickerChange"
					></v-date-picker>
				</v-menu>
			</div>
			<v-btn x-small text @click.native="incrementOrDecrementDay(1)" class="pa-0 py-4 mt-1" :disabled="disabled">
				<v-icon class="pa-0">far fa-chevron-right</v-icon>
			</v-btn>
		</v-col>
	</v-container>
</template>

<script>
export default {
	name: 'BerthPlannerToolbarDateItem',
	props: {
		value: {
			type: Date
		},
		daysCounterVisible: {
			type: Boolean,
			default: false
		},
		days: {
			type: Number
		},
		disabled: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			selectedDate: null,
			dateSelectorVisible: true,
			menuDate: false
		};
	},
	computed: {
		selectedStringDate: {
			get() {
				if (!this.selectedDate) return null;

				const dd = (this.selectedDate.getDate() < 10 ? '0' : '') + this.selectedDate.getDate();
				const MM = (this.selectedDate.getMonth() + 1 < 10 ? '0' : '') + (this.selectedDate.getMonth() + 1);
				const yyyy = this.selectedDate.getFullYear();
				return `${yyyy}-${MM}-${dd}`;
			},

			set() {}
		},
		selectedStringFormattedDate() {
			if (!this.selectedStringDate) return null;

			const [year, month, day] = this.selectedStringDate.split('-');
			return `${day}/${month}/${year}`;
		}
	},
	watch: {
		value(v) {
			this.selectedDate = v;
		}
	},
	created(v) {
		this.selectedDate = this.value;
	},
	mounted() {
		this.dateSelectorVisible = !this.daysCounterVisible;
	},
	methods: {
		showSelector() {
			this.dateSelectorVisible = false;
		},
		incrementOrDecrementDay(dayIncrement) {
			let selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + dayIncrement));
			this.$emit('input', selectedDate);
		},
		onDatePickerChange(v) {
			this.menuDate = false;
			let selectedDate = new Date(v);
			this.$emit('change', selectedDate);
		}
	}
};
</script>

<style lang="postcss">
.dateItem {
	& .date-field-start,
	.date-field-end {
		& .v-text-field__slot {
			margin-top: -4px;
		}
	}
}
</style>
