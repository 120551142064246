import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointItemTypes } from '../../items/JointTypes.js';
import JointUtils from '../../utils/JointUtils.js';

class JointDaysAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, days, now) {
		this.jointMain = jointMain;

		this.days = days;
		this.now = now;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		this.dayOfWeekHeight = (this.jointMain.paperHeight - this.topAxisHeight) / this.days.length;

		this.hours = ['02:00', '08:00', '14:00', '20:00'];
		this.hourOfDayHeight = this.dayOfWeekHeight / this.hours.length;
	}

	render() {
		this.renderDaysOfWeek();
		this.renderMonth();
		this.renderDayDelimiters();
		this.renderHoursOfDay();
		this.renderDayForecast();
		this.days.length > 0 && this.renderCurrentHourDelimiter();
		this.renderHourDelimiters();
	}

	// DIAS DE LA SEMANA
	renderDaysOfWeek() {
		for (let n = 0; n < this.days.length; n++) {
			let jointItemConf = new JointItemConf(
				JointItemTypes.DayOfWeek,
				this.days[n].forecastInfo
					? JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { weekday: 'short' })) +
					  '. ' +
					  JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { day: 'numeric' })) +
					  ' ' +
					  JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { month: 'short' }))
					: JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { weekday: 'short' })) +
					  '. ' +
					  JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { day: 'numeric' })),
				0,
				this.topAxisHeight + this.dayOfWeekHeight * n,
				JointSettings.axis.dayOfWeekWidth,
				this.dayOfWeekHeight
			);

			let dayOfWeek = new JointItem(jointItemConf, this.jointMain);
			dayOfWeek.render();
		}
	}

	// DIAS DEL MES
	renderMonth() {
		for (let n = 0; n < this.days.length; n++) {
			let jointItemConf = new JointItemConf(
				JointItemTypes.Month,
				this.days[n].forecastInfo
					? ''
					: JointUtils.capitalizeFirstLetter(this.days[n].date.toLocaleString(this.days[n].locale, { month: 'short' })),
				JointSettings.axis.dayOfWeekWidth / 3,
				this.days[n].forecastInfo ? this.topAxisHeight + this.dayOfWeekHeight * n - 5 : this.topAxisHeight + this.dayOfWeekHeight * n + 20,
				JointSettings.axis.dayOfWeekWidth / 3,
				this.dayOfWeekHeight
			);

			let month = new JointItem(jointItemConf, this.jointMain);
			month.render();
		}
	}

	// PREDICCIÓN METEOROLÓGICA
	renderDayForecast() {
		for (let n = 0; n < this.days.length; n++) {
			const forecastInfo = this.days[n].forecastInfo;
			if (forecastInfo) {
				let jointItemConf = new JointItemConf(
					JointItemTypes.DayForecast,
					forecastInfo.temperatureLabel + '\n' + forecastInfo.windLabel + '\n' + forecastInfo.maxTideLabel,
					0,
					this.topAxisHeight + this.dayOfWeekHeight * n + 30,
					JointSettings.axis.dayOfWeekWidth,
					this.dayOfWeekHeight
				);

				let dayForecast = new JointItem(jointItemConf, this.jointMain);
				dayForecast.render();
			}
		}
	}

	// DELIMITADORES DE DIA
	renderDayDelimiters() {
		for (let n = 0; n < this.days.length; n++) {
			let line = V('line', {
				x1: 0,
				y1: this.topAxisHeight + n * this.dayOfWeekHeight,
				x2: this.jointMain.paperWidth,
				y2: this.topAxisHeight + n * this.dayOfWeekHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 4
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	// HORAS
	renderHoursOfDay() {
		for (let n = 0; n < this.hours.length * this.days.length; n++) {
			let jointItemConf = new JointItemConf(
				JointItemTypes.HourOfDay,
				this.hours[n % 4],
				JointSettings.axis.dayOfWeekWidth,
				this.topAxisHeight + this.hourOfDayHeight * n,
				JointSettings.axis.hourOfDayWidth,
				this.hourOfDayHeight
			);

			let hourOfDay = new JointItem(jointItemConf, this.jointMain);
			hourOfDay.render();
		}
	}

	// DELIMITADORES DE HORA
	renderHourDelimiters() {
		for (let n = 0; n < this.hours.length * this.days.length; n++) {
			let lineColor = JointSettings.colors.grid.lines;
			let strokeWidth = 1;
			if (n % 4 == 0) {
				lineColor = JointSettings.colors.grid.dayLine;
				strokeWidth = 2;
			}

			let line = V('line', {
				x1: 0,
				y1: this.topAxisHeight + n * this.hourOfDayHeight,
				x2: this.jointMain.paperWidth,
				y2: this.topAxisHeight + n * this.hourOfDayHeight,
				stroke: lineColor,
				'stroke-width': strokeWidth
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	// DELIMITADOR HORA ACTUAL
	renderCurrentHourDelimiter() {
		const firstDayDistance = parseInt(parseInt(this.now.getDate() - this.days[0].date.getDate()));
		const isNowVisible = firstDayDistance < 0 || firstDayDistance > 6 ? false : true;

		if (isNowVisible) {
			let line = V('line', {
				x1: 0,
				y1: this.getYpositionFromDate(this.now),
				x2: this.jointMain.paperWidth,
				y2: this.getYpositionFromDate(this.now),
				stroke: JointSettings.colors.grid.currentTime,
				'stroke-width': 2
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	getYpositionFromDate(date) {
		if (typeof date == 'string') {
			date = new Date(date);
		}

		/* if (date.getMonth() == this.days[0].date.getMonth()) { firstDayDistance = parseInt(parseInt(date.getDate() - this.days[0].date.getDate()));
		} else { firstDayDistance = this.dateDiff(this.days[0].date, date); } */

		if (date) {
			const initial_offset = this.topAxisHeight - (this.hourOfDayHeight * 2) / 6;
			const firstDayDistance = this.dateDiff(this.days[0].date, date);
			const hours_difference = date.getHours() - this.days[0].date.getHours();

			const Y = initial_offset + firstDayDistance * this.hourOfDayHeight * 4 + (this.hourOfDayHeight / 6) * hours_difference;

			return Y;
		}

		return null;
	}

	dateDiff(first, second) {
		return Math.floor((second - first) / (1000 * 60 * 60 * 24));
	}

	getDateFromYPosition(y) {
		let visibleDays = 7;
		let hourIntervals = 4;
		let totalIntervals = visibleDays * hourIntervals;

		let totalSeconds = visibleDays * 24 * 60 * 60;
		let totalPx = totalIntervals * this.hourOfDayHeight;

		let secondsPerPixel = totalSeconds / totalPx;

		let yDate = y - this.topAxisHeight;
		let time = yDate * secondsPerPixel;

		//----------------------

		let days = Math.floor(time / (3600 * 24));
		let hours = Math.floor((time % (3600 * 24)) / 3600);
		let minutes = Math.floor((time % 3600) / 60);

		let finalDate = new Date();

		// falta controlar salto de año y de mes

		finalDate.setFullYear(this.days[0].date.getFullYear());
		finalDate.setMonth(this.days[0].date.getMonth());

		finalDate.setDate(this.days[0].date.getDate() + days);
		finalDate.setHours(hours + 2); // starts at 2:00

		finalDate.setMinutes(minutes);

		return finalDate;
	}
}

export default JointDaysAxis;
