<template>
	<v-dialog v-model="visibleDialog" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.saveVersion.title') }}</span>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3 d-flex">
				<v-form style="width: 100%" ref="form" lazy-validation @submit.prevent>
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<pui-text-field
								:id="`name-stopblock`"
								v-model="data.description"
								:label="$t('berthplanner.saveVersion.description')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn outlined @click="back">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" @click="conntinue">{{ $t('pui9.accept') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			visibleDialog: false,
			data: {}
		};
	},
	watch: {},
	mounted() {},
	methods: {
		back() {
			this.$emit('response', false);
			this.visibleDialog = false;
		},
		conntinue() {
			if (!this.$refs.form.validate()) {
				return;
			}
			const start = new Date(this.data.startDate);
			const end = new Date(this.data.endDate);
			let startdate = new Date(start.setDate(start.getDate() - 1));
			startdate.setHours(0, 0, 0, 0);
			let internalModel = {
				description: this.data.description,
				berthid: this.data.berths ? this.data.berths[0].id : null,
				dockid: this.data.dock.id,
				dateiniplan: startdate.toISOString(),
				datefinplan: end.toISOString(),
				berthingjson: this.fillJson(this.data.stops)
			};
			//
			this.$puiRequests.postRequest(
				'/berthingplanversion/insert',
				internalModel,
				() => {
					this.visibleDialog = false;
					this.$emit('newVersion');
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$puiNotify.error(this.$t('pui9.save.error'));
				}
			);
		},
		fillJson(data) {
			return JSON.stringify(
				{
					data: data,
					sumData: {},
					totalPages: 0,
					currentPage: 0,
					totalRecords: data.length,
					currentRecords: data.length
				},
				null,
				2
			);
		}
	}
};
</script>
