<template>
	<v-dialog v-model="value" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.berthblock') }}</span>
				<v-spacer />
				<v-btn style="min-width: 32px" small depressed @click="closeDialog()">
					<i class="far fa-times"></i>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3">
				<v-form class="pt-3" ref="form" lazy-validation @submit.prevent>
					<v-row class="pui-form-layout">
						<v-flex xs12>
							<!-- LOCATIONID -->
							<span>{{ $t('stopblock.locationid') }}</span>
							<pui-select
								:id="`locationid-${modelName}`"
								:attach="`locationid-${modelName}`"
								v-model="berthModel"
								modelName="berth"
								itemValue="id"
								:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
								:itemsToSelect="berthItemsToSelect"
								:fixedFilter="berthFilter"
								:placeholder="$t('stopblock.locationid')"
								return-object
								toplabel
								clearable
								reactive
								@change="getBollardsFromBerth"
							></pui-select>
						</v-flex>
						<v-flex xs12>
							<!-- DATEINI -->
							<pui-date-field
								:id="`dateini-stopblock`"
								v-model="internalModel.dateini"
								:max="internalModel.dateend"
								:label="$t('stopblock.dateini')"
								required
								toplabel
								time
							></pui-date-field>

							<pui-date-field
								:id="`dateend-stopblock`"
								v-model="internalModel.dateend"
								:label="$t('stopblock.dateend')"
								:min="internalModel.dateini"
								required
								toplabel
								time
							></pui-date-field>
						</v-flex>

						<v-flex xs12>
							<v-row dense v-if="enableBollards">
								<v-col>
									<pui-select
										v-model="bollardiniModel"
										ref="bollardini-stopblock"
										id="bollardini-stopblock"
										:label="$t('stopblock.bollardini')"
										modelName="bollard"
										:itemsToSelect="bollardiniidItemsToSelect"
										:rules="bollardRules"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthBollardsFilter"
										:order="{ orderby: 'asc' }"
										return-object
										toplabel
										reactive
									></pui-select>
								</v-col>
								<v-col>
									<pui-select
										v-model="bollardendModel"
										ref="bollardend-stopblock"
										id="bollardend-stopblock"
										:label="$t('stopblock.bollardend')"
										modelName="bollard"
										:itemsToSelect="bollardendidItemsToSelect"
										:rules="bollardRules"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthBollardsFilter"
										:order="{ orderby: 'asc' }"
										return-object
										toplabel
										reactive
									></pui-select>
								</v-col>
							</v-row>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:id="`name-stopblock`"
								v-model="internalModel.name"
								:label="$t('stopblock.name')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-flex>
					</v-row>
				</v-form>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-icon color="red accent-4" @click="deleteBlock()" v-if="internalModel.id && userFunctionalities.has_UPDATE_BERTHBLOCK"
					>fas fa-trash-alt</v-icon
				>
				<v-spacer></v-spacer>
				<v-btn text @click="closeDialog()">{{ $t('form.cancel') }}</v-btn>
				<v-btn
					color="primary"
					text
					@click="save()"
					v-if="(this.internalModel.id == null && userFunctionalities.has_INSERT_BERTHBLOCK) || userFunctionalities.has_UPDATE_BERTHBLOCK"
					>{{ $t('form.save') }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'BerthPlannerBerthBlockDialog',
	props: {
		value: {
			type: Boolean,
			required: true
		},
		currentBerth: {
			default() {
				return {};
			},
			required: false
		},
		berthFilter: {
			type: Object,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			modelName: 'berthblock',
			internalModel: this.getEmptyModel(),
			enableBollards: false,
			berthModel: {},
			bollardiniModel: {},
			bollardendModel: {},
			disabled: !this.userFunctionalities.has_UPDATE_BERTHBLOCK
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		},
		storedModel: {
			get: function () {
				return this.$store.state.berthPlanner.configuration.berthBlockDialogModel;
			},
			set: function (newValue) {
				return (this.$store.state.berthPlanner.configuration.berthBlockDialogModel = newValue);
			}
		},
		berthBollardsFilter() {
			// bollards pui-select filter
			let locationid = this.internalModel && this.internalModel.locationid ? this.internalModel.locationid : this.currentBerth.id;

			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berth_id', op: 'eq', data: locationid }]
			};
		},
		berthItemsToSelect() {
			return [{ id: this.internalModel.locationid }];
		},
		bollardiniidItemsToSelect() {
			return [{ id: this.internalModel.bollardiniid }];
		},
		bollardendidItemsToSelect() {
			return [{ id: this.internalModel.bollardendid }];
		},
		bollardRules() {
			if (this.bollardiniModel && this.bollardendModel) {
				return [this.bollardiniModel.orderby < this.bollardendModel.orderby || this.$t('stop.bollardsException')];
			}
			return [];
		}
	},
	watch: {
		'currentBerth.id'() {
			this.getBollardsFromBerth();
		},
		berthModel: {
			deep: true,
			handler(v) {
				this.internalModel.locationid = v.id;
			}
		},
		bollardiniModel(v) {
			this.internalModel.bollardinicode = v.bolcode;
			this.internalModel.bollardini = this.internalModel.bollardiniid = v.id;
			this.internalModel.bollardiniorderby = v.orderby;
		},
		bollardendModel(v) {
			this.internalModel.bollardendcode = v.bolcode;
			this.internalModel.bollardend = this.internalModel.bollardendid = v.id;
			this.internalModel.bollardiniorderby = v.orderby;
		}
	},
	mounted() {
		this.getBollardsFromBerth();
		this.storedModel ? (this.internalModel = this.storedModel) : (this.internalModel = this.getEmptyModel());
		if (this.currentBerth.id) {
			this.internalModel.locationid = this.currentBerth.id;
		}
	},
	methods: {
		getEmptyModel() {
			return {
				bollardini: null,
				bollardend: null,

				bollardinicode: null,
				bollardiniid: null,
				bollardiniorderby: null,
				bollardendcode: null,
				bollardendid: null,
				bollardendorderby: null,
				locationid: null
			};
		},
		getBollardsFromBerth() {
			//this.bollardiniModel = {};
			//this.bollardendModel = {};

			const opts = {
				model: 'berth',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.internalModel.locationid
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data.length > 0 && response.data.data[0].initbol && response.data.data[0].endbol) {
					this.enableBollards = true;
				} else {
					this.enableBollards = false;
				}
			});
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.internalModel.id) {
					this.$puiRequests.putRequest(
						'/berthblock/update',
						this.internalModel,
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						}
					);
				} else {
					this.$puiRequests.postRequest(
						'/berthblock/insert',
						this.internalModel,
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						}
					);
				}
				this.$puiEvents.$emit('operationscountdown_reload');
				this.closeDialog();
			}
		},
		deleteBlock() {
			const params = {
				id: this.internalModel.idblock
			};
			this.$puiRequests.deleteRequest(
				'/berthblock/delete?id=' + this.internalModel.id,
				null,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				params
			);
			this.$puiEvents.$emit('operationscountdown_reload');
			this.closeDialog();
		},
		closeDialog() {
			this.storedModel = null;
			this.$emit('input', false);
		}
	}
};
</script>
