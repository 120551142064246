import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointBoardTypes, JointItemTypes } from '../../items/JointTypes.js';
import JointUtils from '../../utils/JointUtils.js';

import JointBollardsAxis from './JointBollardsAxis.js';
import JointDaysAxis from './JointDaysAxis.js';

class JointBollardsBerthBoard {
	constructor(jointMain, days, now, bollards, bollardNodes, berths, stops, blocks, bookings) {
		this.jointMain = jointMain;
		this.JointBoardType = JointBoardTypes.BollardsBerth;
		this.jointMain.jointBoard = this;
		this.berths = berths;
		this.bollards = bollards;

		this.topAxisHeight = berths && berths.length > 0 ? JointSettings.axis.bollardHeight * 2 : JointSettings.axis.bollardHeight;
		this.leftAxisWidth = JointSettings.axis.dayOfWeekWidth + JointSettings.axis.hourOfDayWidth;

		this.jointLeftAxis = new JointDaysAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, days, now);
		this.jointTopAxis = new JointBollardsAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, bollards, bollardNodes, berths);

		this.jointTopAxis.render();
		this.jointLeftAxis.render();

		this.leftBoundary = this.leftAxisWidth;
		this.rightBoundary = this.leftAxisWidth + this.jointTopAxis.availableSpaceForBollards;
		this.roundedLeftBoundary = Math.round(this.leftBoundary);
		this.roundedRightBoundary = Math.round(this.rightBoundary);

		this.maxVesselWidth = this.roundedRightBoundary - this.roundedLeftBoundary;

		this.addPatterns();
		this.renderItems(bollardNodes, stops, blocks, bookings);

		//var link = new shapes.standard.Link();
		//link.source(resource1.rectangle);
		//link.target(resource2.rectangle);
		//link.addTo(this.graph);
		//this.jointMain.paper.scale(0.5, 0.5);
		//this.jointMain.paper.translate(300, 50);
	}

	addPatterns() {
		// this.pattern = V('<pattern id="pepe" patternUnits="userSpaceOnUse"><image/></pattern>');
		// cache the image element for a quicker access
		// this.patternImage = this.pattern.findOne('image');

		let blackPattern = V(
			'<pattern id="backgroundBlackPattern" x="0" y="0" width="400" height="227" patternUnits="userSpaceOnUse">' +
				'<line x1="-300" y1="-10" x2="100" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="-250" y1="-10" x2="150" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="-200" y1="-10" x2="200" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="-150" y1="-10" x2="250" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="-100" y1="-10" x2="300" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="-50" y1="-10" x2="350" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="0" y1="-10" x2="400" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="50" y1="-10" x2="450" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="100" y1="-10" x2="500" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="150" y1="-10" x2="550" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="200" y1="-10" x2="600" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="250" y1="-10" x2="650" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="300" y1="-10" x2="700" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="350" y1="-10" x2="750" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="400" y1="-10" x2="800" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="450" y1="-10" x2="850" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'<line x1="500" y1="-10" x2="900" y2="900" stroke="black" stroke-width="20" opacity="0.1" />' +
				'</pattern>'
		);

		V(this.jointMain.paper.svg).defs().append(blackPattern);

		let yellowPattern = V(
			'<pattern id="backgroundYellowPattern" x="0" y="0" width="400" height="800" patternUnits="userSpaceOnUse">' +
				'<line x1="-150" y1="-10" x2="100" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="-100" y1="-10" x2="150" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="-50" y1="-10" x2="200" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="0" y1="-10" x2="250" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="50" y1="-10" x2="300" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="100" y1="-10" x2="350" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="150" y1="-10" x2="400" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="200" y1="-10" x2="450" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="250" y1="-10" x2="500" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="300" y1="-10" x2="550" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="350" y1="-10" x2="600" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="400" y1="-10" x2="650" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="450" y1="-10" x2="700" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'<line x1="500" y1="-10" x2="750" y2="860" stroke="#FCE9BF" stroke-width="20" opacity="0.8" />' +
				'</pattern>'
		);

		V(this.jointMain.paper.svg).defs().append(yellowPattern);
	}

	renderItems(bollardNodes, stops, blocks, bookings) {
		this.boardItems = [];

		if (bollardNodes.length > 0) {
			for (const booking of bookings) {
				if (this.jointMain.userFunctionalities.has_READ_BOOKING) {
					let bookingBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(booking.bollardinicode);
					let bookingBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(booking.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!bookingBollardiniX) {
						bookingBollardiniX = this.jointTopAxis.getStartBerthLimitBolcodeFromBerthId(booking.berth);
					}

					if (!bookingBollardendX) {
						bookingBollardendX = this.jointTopAxis.getEndBerthLimitBolcodeFromBerthId(booking.berth);
					}

					let itemWidth = bookingBollardendX - bookingBollardiniX;

					let dateini = this.jointLeftAxis.getYpositionFromDate(booking.eta);
					let dateend = this.jointLeftAxis.getYpositionFromDate(booking.etd);
					let itemHeight = dateend - dateini;

					let jsItemConf = new JointItemConf(
						JointItemTypes.Booking,
						JointUtils.getLabelFromBooking(booking, itemHeight),
						bookingBollardiniX,
						dateini,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, booking);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			for (const block of blocks) {
				if (this.jointMain.userFunctionalities.has_LIST_BERTHBLOCK) {
					let blockBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(block.bollardinicode);
					let blockBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(block.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!blockBollardiniX) {
						blockBollardiniX = this.jointTopAxis.getStartBerthLimitBolcodeFromBerthId(block.locationid);
					}

					if (!blockBollardendX) {
						blockBollardendX = this.jointTopAxis.getEndBerthLimitBolcodeFromBerthId(block.locationid);
					}

					let itemWidth = blockBollardendX - blockBollardiniX;

					let dateini = this.jointLeftAxis.getYpositionFromDate(block.dateini);
					let dateend = this.jointLeftAxis.getYpositionFromDate(block.dateend);
					let itemHeight = dateend - dateini;

					let jsItemConf = new JointItemConf(
						JointItemTypes.BerthBlock,
						JointUtils.getLabelFromBlock(block, itemHeight),
						blockBollardiniX,
						dateini,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, block);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			for (const stop of stops) {
				if (this.jointMain.userFunctionalities.has_READ_STOP) {
					let stopBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(stop.bollardinicode);
					let stopBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(stop.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!stopBollardiniX) {
						stopBollardiniX = this.jointTopAxis.getStartBerthLimitBolcodeFromBerthId(stop.berthid);
					}

					if (!stopBollardendX) {
						stopBollardendX = this.jointTopAxis.getEndBerthLimitBolcodeFromBerthId(stop.berthid);
					}

					let itemWidth = stopBollardendX - stopBollardiniX;

					let etalocalY = this.jointLeftAxis.getYpositionFromDate(stop.etalocal || stop.eta);
					let etdlocalY = this.jointLeftAxis.getYpositionFromDate(stop.etdlocal || stop.etd);
					let itemHeight = etdlocalY - etalocalY;

					let jsItemConf = new JointItemConf(
						JointItemTypes.Vessel,
						JointUtils.getLabelFromStop(stop, itemHeight),
						stopBollardiniX,
						etalocalY,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, stop);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			setTimeout(() => {
				this.jointMain.jointZManager.axisToFront();
			}, 15);
		}
	}

	getBerthFromBolcode(bolcode) {
		// bookings bolcodes come as number and we check === to prevent undefined / null values
		if (bolcode) {
			bolcode = bolcode.toString();
		}

		if (bolcode.startsWith('start_') || bolcode.startsWith('end_')) {
			let berthid = bolcode.split('_')[1];
			for (let berth of this.berths) {
				if (berthid == berth.id) {
					return berth;
				}
			}
		} else {
			let bollardModel = null;
			for (let bollard of this.bollards) {
				if (bollard.bolcode == bolcode) {
					bollardModel = bollard;
				}
			}
			for (let berth of this.berths) {
				if (bollardModel.berthid == berth.id) {
					return berth;
				}
			}
		}
	}
}

export default JointBollardsBerthBoard;
