<template>
	<v-dialog v-model="visible" scrollable>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.vesselsoutofrange') }}</span>
				<v-spacer />
				<v-btn style="min-width: 32px" small depressed @click="visible = false">
					<i class="far fa-times"></i>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-text>
				<pui-simple-datatable v-if="visible" :modelName="modelName" :modelColumnDefs="modelColumnDefs" :data="stops" :columns="columns" />
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		stops: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			visible: false,
			modelName: 'berthplanner',
			model: undefined,
			visibleColumns: ['portcallnumber','vesselimo','vesselname','vesseltype','vessellength','vesselbeam','status','etaplanner','etdplanner','bollardini','bollardend'],
			modelColumnDefs: [],
			dateTimeColumnIndexes: [],
			columns: []
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.model = this.$store.getters.getModelByName(this.modelName);
			this.onReady();
		}
	},
	mounted() {
		this.model = this.$store.getters.getModelByName(this.modelName);
		if (this.model) {
			this.onReady();
		}
	},
	methods: {
		onReady() {
			const modelColumns = this.model.columns;
			for (let i = 0, columnsLength = modelColumns.length; i < columnsLength; i++) {
				modelColumns[i].title = this.$t(modelColumns[i].title);
				this.columns.push({
					name: modelColumns[i].name,
					data: modelColumns[i].name,
					title: modelColumns[i].title,
					visible: this.visibleColumns.includes(modelColumns[i].name),
					orderable: true
				});
				if (modelColumns[i].type === 'datetime') {
					this.dateTimeColumnIndexes.push(i);
				}
			}

			this.dateFormat = this.$store.getters.dateFormat;
			this.timeFormat = this.$store.getters.timeFormat;

			const dateTimeColumnDefs = {
				targets: this.dateTimeColumnIndexes,
				render: (val) => {
					if (val === null) {
						return '';
					}
					return this.$dateTimeUtils.getLocalFormattedDate(val, `${this.dateFormat} ${this.timeFormat}`);
				}
			};

			this.modelColumnDefs.push(dateTimeColumnDefs);
		}
	}
};
</script>
