<template>
	<v-dialog v-model="visibleDialog" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.confirmVersion.title') }}</span>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3 d-flex">
				<v-container class="px-6 py-0" v-if="!loading">{{ $t('berthplanner.confirmVersion.advise') }}</v-container>

				<v-container class="px-6 py-0" v-if="loading"> <v-progress-linear indeterminate rounded height="6"></v-progress-linear></v-container>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn outlined @click="back">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" @click="conntinue">{{ $t('pui9.accept') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			visibleDialog: false,
			versionId: {},
			json: {},
			loading: false
		};
	},
	watch: {},
	mounted() {},
	methods: {
		back() {
			this.$emit('response', false);
			this.visibleDialog = false;
		},
		conntinue() {
			this.loading = true;
			this.$puiRequests.postRequest(
				'/vstopberthplanner/confirmVersion',
				this.json,
				(response) => {
					try {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.loading = false;
						this.visibleDialog = false;
					} catch (error) {
						this.loading = false;
						this.$puiNotify.error(this.$t('pui9.save.error'));
					}
				},
				(error) => {
					this.loading = false;
					this.$puiNotify.error(this.$t('pui9.save.error'));
				},
				{ id: this.versionId }
			);
		}
	}
};
</script>
<style lang="postcss">
@import '../../../styles/app-variables.pcss';

.title {
	color: var(--primary);
	font-size: 12px;
	font-family: Montserrat;
}
.berthplannerTable {
	border-collapse: collapse;
	width: 100%;
	background-color: #d7ebff;
	font-family: Montserrat;
}

.berthplannerTh {
	text-align: left;
	color: #6f7480;
	padding: 8px;
	font-weight: lighter;
}

.berthplannerTd {
	border: 1px solid #dadde0;
	text-align: left;
	padding: 2px;
	padding-left: 4px;
}

.berthplannerTr:nth-child(even) {
	background-color: white;
}

.berthplannerTr:nth-child(odd) {
	background-color: #f7f8fc;
}
</style>
