<template>
	<v-container class="pa-0 d-flex" :style="range && $refs.endDateItem && !$refs.endDateItem.daysCounterVisible ? 'width: 300px;' : 'width: 600px;'">
		<berth-planner-toolbar-date-item
			v-model="value.startDate"
			:disabled="disabled"
			@input="updateStartDateFromBtn"
			@change="updateStartDateFromPicker"
		/>
		<berth-planner-toolbar-date-item
			ref="endDateItem"
			daysCounterVisible
			v-if="range"
			v-model="value.endDate"
			:days="days"
			:disabled="disabled"
			@input="updateEndDate"
			@change="updateEndDate"
		/>
		<berth-planner-save-changes-dialog ref="berthPlannerSaveChangesDialog" @response="updateDates" />
	</v-container>
</template>

<script>
import BerthPlannerToolbarDateItem from './BerthPlannerToolbarDateItem.vue';
import BerthPlannerSaveChangesDialog from './BerthPlannerSaveChangesDialog.vue';

export default {
	name: 'BerthPlannerToolbarDateSelector',
	components: {
		BerthPlannerToolbarDateItem,
		BerthPlannerSaveChangesDialog
	},
	props: {
		value: {
			type: Object
		},
		range: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			required: true
		},
		savingEnabled: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			internalModel: {
				startDate: this.value.startDate,
				endDate: this.value.endDate
			}
		};
	},
	computed: {
		days() {
			return Math.round((this.internalModel.endDate.getTime() - this.internalModel.startDate.getTime()) / (1000 * 60 * 60 * 24) + 1);
		}
	},
	watch: {
		value(v) {
			if (v != v) {
				this.internalModel = v;
			}
		}
	},
	methods: {
		updateStartDateFromPicker(v) {
			this.internalModel.startDate = v;
			if (this.$refs.endDateItem && !this.$refs.endDateItem.dateSelectorVisible) {
				this.updateEndDateFromStartDate(v);
			}
			this.checkSaveChangesDialog();
		},
		updateStartDateFromBtn(v) {
			this.internalModel.startDate = v;
			if (this.$refs.endDateItem && !this.$refs.endDateItem.dateSelectorVisible) {
				this.updateEndDateFromStartDate(v);
			}
			this.checkSaveChangesDialog();
		},
		updateEndDateFromStartDate(v) {
			this.internalModel.endDate = new Date(v.getFullYear(), v.getMonth(), v.getDate() + 5);
		},
		updateEndDate(v) {
			this.internalModel.endDate = v;
			this.checkSaveChangesDialog();
		},
		checkSaveChangesDialog() {
			if (this.savingEnabled && !this.disabled) {
				this.$refs.berthPlannerSaveChangesDialog.visibleDialog = true;
			} else {
				this.updateDates();
			}
		},
		updateDates() {
			this.$emit('input', this.internalModel);
		}
	}
};
</script>
